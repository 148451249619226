
  import { channelColors } from '@/utils/channelColors'
  import { financingColors } from '@/utils/financingColors'
  import { deepCopy, localToUtc, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'

@Component
  export default class SupervisorSales extends SupervisorDashboard {
    // Methods
    beforeCreate () {
      this.getData = async () => {
        await this.getGeneralResults()
      }
    }

    sortBySoldDate (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.soldDate).isBefore(dayjs(next.soldDate))) {
          return 1
        } else if (dayjs(prev.soldDate).isAfter(dayjs(next.soldDate))) {
          return -1
        }
        return 0
      })
    }

    transformDailyUtility (records) {
      return this.transformUtility(records, 'day')
    }

    transformMonthlyUtility (records) {
      return this.transformUtility(records, 'month')
    }

    transformUtility (records, granularity: 'day' | 'month', debugging = false) {
      const { getObjectAttribute } = this
      const { start, end } = granularity === 'day' ? this.dates : this.lastYearDates

      const recordsCopy = deepCopy(records)

      for (let i = 0; i < recordsCopy.length; i++) {
        this.$set(recordsCopy[i], 'records', recordsCopy[i].records?.records?.nodes || recordsCopy[i].records?.records || recordsCopy[i].records?.records || recordsCopy[i].records || [])
      }

      const purchasesRecords = recordsCopy?.filter(record => record.name === 'Compras')[0]
      const salesRecords = recordsCopy?.filter(record => record.name === 'Ventas')[0]
      const creditsRecords = recordsCopy?.filter(record => record.name === 'Créditos')[0]

      const ownStockSales = salesRecords.records.filter(sale => sale.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'purchase')
      const ownPurchases = purchasesRecords.records.filter(purchase => purchase.acquisitionType.name === 'purchase')

      const consignedStockSales = salesRecords.records.filter(sale => sale.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'consignment')
      const consignments = purchasesRecords.records.filter(purchase => purchase.acquisitionType.name === 'consignment')

      if (!purchasesRecords || !salesRecords || !creditsRecords) return

      let newDate = dayjs(start)
      const limitRange = [newDate]
      while (newDate.isBefore(dayjs(end))) {
        newDate = newDate.add(1, granularity)
        if (newDate.isBefore(dayjs(end))) {
          limitRange.push(newDate)
        }
      }
      if (debugging) console.log('DATES', limitRange)

      // Stock propio
      const ownStockUtility = limitRange.map(date => {
        const sales = ownStockSales.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, granularity))
        const purchases = ownPurchases.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, granularity))
        let utility = 0
        sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
        purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
        return {
          date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
          utility,
        }
      })

      // Stock consignado
      const consignedStockUtility = limitRange.map(date => {
        const sales = consignedStockSales.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, granularity))
        const purchases = consignments.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, granularity))
        let utility = 0
        sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
        purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
        return {
          date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
          utility,
        }
      })
      // Creditos
      const creditsUtility = limitRange.map(date => {
        const credits = creditsRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, creditsRecords.pathToDate))).isSame(date, granularity))
        let utility = 0
        credits.forEach(credit => utility += getObjectAttribute(credit, creditsRecords.pathToValue))
        return {
          date: date.format('YYYY-MM-DD'),
          utility,
        }
      })

      return [
        {
          name: 'Stock propio',
          records: ownStockUtility,
          color: '#6185DB',
          pathToDate: ['date'],
          pathToValue: ['utility'],
          type: 'bar',
        },
        {
          name: 'Consignaciones',
          records: consignedStockUtility,
          color: '#FAD901',
          pathToDate: ['date'],
          pathToValue: ['utility'],
          type: 'bar',
        },
        {
          name: 'Créditos',
          records: creditsUtility,
          color: '#7D67BC',
          pathToDate: ['date'],
          pathToValue: ['utility'],
          type: 'bar',
        },
      ]
    }

    sortBySignedDate (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.signedDate).isBefore(dayjs(next.signedDate))) {
          return 1
        } else if (dayjs(prev.signedDate).isAfter(dayjs(next.signedDate))) {
          return -1
        }
        return 0
      })
    }

    fixCreditFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { auto, executive } = corrected[i]
        const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
        const executiveFullName = `${executive?.person.firstName.trim()} ${executive?.person.surname.trim()}`
        if (executive) {
          corrected[i].executive.fullName = executiveFullName
        }
        corrected[i].signedDate = dayjs(corrected[i].signedDate).format('DD/MM/YY')
        corrected[i] = {
          ...corrected[i],
          carFullName,
          executiveFullName,
        }
      }

      return corrected
    }

    normaliceReserves (records) {
      return records.map(record => {
        const { executive, executiveName } = record
        record.executive = executive ? {
          id: executive,
          name: executiveName,
        } : undefined
        return record
      })
    }

    setFinancingColors (records) {
      return records.map(record => {
        const { person } = record
        person.color = financingColors[person.alias.toUpperCase()]
        record.person = person
        return record
      })
    }

    normaliceSales (records) {
      return this.normaliceReserves(this.setChannelColors(records))
    }

    normaliceReservesQuery (records) {
      return records.map(record => {
        const { executive } = record.saleOrder.deal.lead
        const { person } = executive
        record.executive = {
          id: executive.id,
          name: `${person.firstName} ${person.surname}`,
        }
        return record
      })
    }

    normalicePurchases (records) {
      return this.setChannelColors(records)
        .map(record => {
          const { executive } = record
          const person = executive?.person
          record.executive = {
            id: executive?.id,
            name: person ? `${person.firstName} ${person.surname}` : 'SIN EJECUTIVO',
          }
          return record
        })
    }

    setChannelColors (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { channel } = corrected[i]
        channel.color = channelColors[channel.name]
        corrected[i].channel = channel
      }
      return corrected
    }

    fixSaleFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { autoDescription, year, executive, executiveName } = corrected[i]
        const carFullName = `${autoDescription} ${year}`
        corrected[i].soldDate = dayjs(corrected[i].soldDate).format('DD/MM/YY - HH:mm')
        corrected[i].executive = executive ? {
          id: executive,
          name: executiveName,
        } : undefined
        corrected[i] = {
          ...corrected[i],
          carFullName,
        }
      }

      return corrected
    }

    // Getters

    // Watchers
  }
